import React from 'react';
import './styles.css';

function ProtagonistasPesquisa() {
  return (
    <div className="sobrepesquisa-container fade-in">
      <h1 className="titulo-pagina">Protagonistas da Pesquisa</h1>
      <div className="texto-pesquisa">
        <p>
          Me chamo Keila Emanuela da costa, nasci em Passos (MG) no dia 02/01/2007.
          Com um ano de idade me mudei para Boa Esperança-PR. Por volta dos meus 6/7
          anos me mudei para Jundiai (SP). Foi um ano passando por trancos e barrancos,
          aí me mudei para Boa Esperança onde moro até hoje. Hoje tenho 17 anos, estou
          terminando o ensino médio, jogo basquete e atualmente estou estudando para fazer
          uma boa faculdade e dar uma condição melhor de vida para minha mãe 
          <strong> (KEILA, 2024)</strong>.
        </p>

        <p>
          Eu me chamo vitória, nasci em 2007, tenho 17 anos, cuido da minha casa e da minha
          irmã para minha mãe. Gosto muito de fazer maratonas de filmes e series, gosto
          também de ficar mais no meu canto. Sou nascida em Boa Esperança, já morei alguns
          meses em Curitiba, estou no último ano do ensino médio e quero muito fazer uma faculdade.
          Meu sonho é fazer fisioterapia, mas infelizmente não tenho possibilidades, mas já estou
          fazendo outros planos. <strong>(VITÓRIA, 2024)</strong>.
        </p>

        <p>
          Me chamo Camila Freitas, nasci em 26 de janeiro de 2007 na cidade de Janiópolis, fruto
          da união de Regiane Rafael da Conceição e de Ademilson de Freitas, atualmente tenho
          17 anos, meu hobbie favorito é cozinhar e gosto de me maquiar e fazer minhas unhas. 
          Meu sonho é me formar em direito, mas por enquanto são apenas sonhos.
          <strong> (CAMILA, 2024)</strong>.
        </p>

        <p>
          Me chamo Luana Ribeiro, tenho 17 anos e moro no município de Boa esperança- Pr.
          Nasci no dia 06 de novembro de 2006 em Jaraguá do sul- SC e vim morar aqui em 2012.
          Eu estudo no colégio Vicente Leporace e estou terminando o 3° ano do ensino médio, minha
          rotina é estudar, pois ainda não trabalho e gosto de aproveitar bem meus finais de semana.
          Apesar de ter tido uma infância difícil hoje em dia sou superfeliz.
          <strong> (LUANA, 2024)</strong>.
        </p>

        <p>
          Meu nome é Leticia Marcovich Cavalcanti, nasci em Campo Mourão- Pr no ano de 2006,
          tenho 18 anos e trabalho como auxiliar administrativo em um supermercado, meus momentos
          de lazer são estar com minha família e amigos. Uma área que tenho muita vontade de estudar
          é a psicologia. Meus hobbies é ler, dançar e assistir séries. <strong>(LETICIA, 2024)</strong>.
        </p>

        <p>
          Me chamo Luiz Eduardo Leal, tenho 17 anos, nasci em Janiópolis dia 7 de agosto de 2007,
          estou prestes a terminar o ensino médio, logo após pretendo me formar em farmácia e
          meu objetivo está sendo entrar na perícia forense. Sou completamente apaixonado por
          música e gosto muito de vôlei. <strong>(LUIZ EDUARDO, 2024)</strong>.
        </p>

        <p>
          Eu me chamo Carolina de Jesus, uma jovem mulher negra de 18 anos, prestes a concluir o
          terceiro ano do ensino médio. Minha personalidade chata, alegre e organizada se equilibra
          com minha paixão pela culinária e pela dedicação aos meus quatro adoráveis animais
          de estimação. Minha jornada até aqui é marcada por determinação, paixão e um
          espírito inabalável. <strong>(CAROLINA, 2024)</strong>.
        </p>

        <p>
          E sou o Carlos Eduardo e gosto de praticar esportes em geral como basquete, futebol etc.
          Estou no último ano da escola, não pretendo fazer faculdade agora e sim fazer cursos de
          barbeiro que no momento é a profissão dos meus sonhos, eu também trabalho como garçom
          aos fins de semana para ajudar a pagar minhas contas. <strong>(CARLOS, 2024)</strong>.
        </p>

        <p>
          Eu sou o Julio Cezar, tenho 17 anos, nasci dia 17 de dezembro de 2006. Meus hobbies são
          praticar esportes, assistir filmes, séries, animes e jogos eletrônicos. Meus planos para
          o futuro são me formar em educação física, me tornar professor e trabalhar para
          sustentar minha família. Minhas ambições não são muito ousadas, apenas quero ter meu
          carro e casa própria e uma vida tranquila. <strong>(JULIO, 2024)</strong>.
        </p>

        <p>
          Me chamo Grabriela de Almeida, tenho 17 anos, gosto de cozinhar, mexer no celular,
          ficar conversando com as minhas amigas, sair com elas, pretendo me formar em estética
          e ter meu próprio espaço para trabalhar. <strong>(GABRIELA, 2024)</strong>.
        </p>

        <p>
          Me chamo Rafaela Liberali, tenho 18 anos de idade, no meu tempo livre gosto de ler
          e ver séries e vou fazer medicina <strong>(RAFAELA, 2024)</strong>.
        </p>

        <p>
          Me chamo Renato Taborda de Oliveira, tenho 17 anos e o que mais gosto de fazer nas
          horas vagas é jogar bola e tocar violão, as vezes eu ando de moto, de carro ou de
          bicicleta, mas o que mais gosto mesmo é jogar bola e tocar violão.
          <strong> (RENATO, 2024)</strong>.
        </p>

        <p>
          Eu me chamo Raissa, nasci dia 22/09/2006 na cidade de Campo Mourão-PR. Atualmente
          estou vivendo uma fase da minha vida que não achei que viveria tão cedo, mas ser
          mãe é um sonho que sempre tive. Não é fácil mudar toda sua rotina e aceitar a
          mudança do seu corpo para gerar um serzinho. Mas ao longo de todo esses nove meses
          eu aceitei e é algo que traz muita felicidade para o meu coração. Estou gostando
          muito dessa nova fase da minha vida. <strong>(RAISSA, 2024)</strong>
        </p>
      </div>
    </div>
  );
}

export default ProtagonistasPesquisa;
