// Importando React e a imagem da logo do programa
import React from 'react';
import logoProfHistoria from '../Images/logoprof.jpg'; // Atualize o caminho conforme necessário
import './styles.css';

function ProfHistoria() {
  return (
    <div className="profhistoria-container fade-in">
      <h1 className="titulo-pagina">Sobre o ProfHistória</h1>
      <div className="sobre-conteudo">
        <div className="foto-coluna">
          <img src={logoProfHistoria} alt="Logo do ProfHistória" className="logo-profhistoria" />
        </div>
        <div className="texto-coluna">
          <p>
            O ProfHistória, programa de pós-graduação stricto sensu em Ensino de História, reconhecido pela Coordenação de Aperfeiçoamento de Pessoal de Nível Superior (CAPES) do Ministério da Educação, visa à formação continuada do docente em História que atua na Educação Básica, propiciando qualificação certificada para o exercício da profissão.
          </p>
          <p>
            O ProfHistória, que conduz ao título de Mestre em Ensino de História, é um curso presencial, com oferta simultânea nacional, coordenado pela Universidade Federal do Rio de Janeiro (UFRJ).
          </p>
          <p>
          Em 2015, o campus de Campo Mourão da Universidade Estadual do Paraná – UNESPAR aderiu ao programa, iniciando a primeira turma de mestrado em 2016. Em 2023, a proposta de Doutorado foi aprovada, iniciando sua primeira turma em 2025.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProfHistoria;
