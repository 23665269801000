// Importar React e os outros componentes necessários
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Nav from './components/Nav';
import Gallery from './components/Gallery';
import Footer from './components/Footer';
import Oficinas from './components/Oficinas';
import Producoes from './components/Producoes';

// Importa os componentes dos Lampejos
import Lampejo1 from './components/Lampejos/lampejo1';
import Lampejo2 from './components/Lampejos/lampejo2';
import Lampejo3 from './components/Lampejos/lampejo3';
import Lampejo4 from './components/Lampejos/lampejo4';
import Lampejo5 from './components/Lampejos/lampejo5';
import Lampejo6 from './components/Lampejos/lampejo6';

// Importar outros componentes
import SobrePesquisadora from './components/SobrePesquisadora';
import GrupoOdisseia from './components/GrupoOdisseia';
import ProfHistoria from './components/ProfHistoria';
import SobrePesquisa from './components/SobrePesquisa';
import ProtagonistasPesquisa from './components/ProtagonistasPesquisa'; // Novo componente

function App() {
  return (
    <Router>
      <div id="app">
        <Header />
        <Nav />
        <Routes>
          <Route path="/" element={<Gallery />} />  {/* Página principal */}
          <Route path="/oficinas" element={<Oficinas />} />  {/* Página de Oficinas */}
          <Route path="/producoes" element={<Producoes />} />  {/* Página de Produções */}
          <Route path="/sobre-pesquisadora" element={<SobrePesquisadora />} />  {/* Página Sobre a Pesquisadora */}
          <Route path="/grupo-odisseia" element={<GrupoOdisseia />} />  {/* Página Grupo Odisseia */}
          <Route path="/prof-historia" element={<ProfHistoria />} />  {/* Página Prof História */}
          <Route path="/sobre-pesquisa" element={<SobrePesquisa />} />  {/* Página Sobre a Pesquisa */}
          <Route path="/protagonistas-pesquisa" element={<ProtagonistasPesquisa />} />  {/* Página Protagonistas da Pesquisa */}

          {/* Rotas para cada Lampejo */}
          <Route path="/lampejo1" element={<Lampejo1 />} />
          <Route path="/lampejo2" element={<Lampejo2 />} />
          <Route path="/lampejo3" element={<Lampejo3 />} />
          <Route path="/lampejo4" element={<Lampejo4 />} />
          <Route path="/lampejo5" element={<Lampejo5 />} />
          <Route path="/lampejo6" element={<Lampejo6 />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
