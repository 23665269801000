// Importando React e as imagens
import React from 'react';
import foto3 from '../Images/FotosOdisseia/foto3.jpg';
import foto4 from '../Images/FotosOdisseia/foto4.jpg';
import foto6 from '../Images/FotosOdisseia/foto6.jpg';
import foto7 from '../Images/FotosOdisseia/foto7.jpg';
import './styles.css';

function GrupoOdisseia() {
  return (
    <div className="grupo-container fade-in">
      <h1 className="titulo-pagina">Grupo Odisseia</h1>
      <div className="sobre-conteudo">
        <div className="fotos-coluna">
          <img src={foto3} alt="Foto do Grupo" className="foto-paisagem" />
          <img src={foto4} alt="Foto do Grupo" className="foto-paisagem" />
          <img src={foto6} alt="Foto do Grupo" className="foto-paisagem" />
          <img src={foto7} alt="Foto do Grupo" className="foto-paisagem" />
        </div>
        <div className="texto-coluna">
          <p>
          O Grupo de Estudos Odisseia, fundado e coordenado desde 2019 por Cyntia Simioni França, 
          adota uma abordagem que explora as memórias inspiradas pelo filósofo Walter Benjamin. 
          Esse grupo, composto por mestrandos, alunos de iniciação científica e professores, 
          se reúne no campus da Unespar de Campo Mourão e enfatiza o valor da rememoração benjaminiana como uma 
          ferramenta para desenvolver pesquisas acadêmicas e práticas educacionais. 
          Nosso objetivo é ampliar sua voz das memórias frequentemente ignoradas pela História eurocêntrica,
           destacando a importância das práticas culturais e sociais dos povos africanos, indígenas, 
           mulheres, crianças urbanas e rurais, trabalhadores rurais, 
           e explorando questões como cultura gótica e gênero. São vozes historicamente
            silenciadas, de sujeitos cujos saberes são, muitas vezes, desconsiderados em favor de uma visão 
            científica eurocêntrica e racional, tipificada pelo homem branco europeu e cristão.
          </p>
  <blockquote className="poema">
      <p>ODISSEIA</p>
      <p>Cyntia Simioni,</p>
      
      <div className="estrofe">
        <p>Há muita história nesse nome,</p>
        <p>Na educação se destaca como Professora,</p>
        <p>Pensando nos diálogos se coloca como intermediadora.</p>
      </div>
      
      <div className="estrofe">
        <p>Assim, para acolher diversas sugestões,</p>
        <p>Propõe um grupo para debater questões</p>
        <p>E fortalecer a caminhada acadêmica,</p>
        <p>Surge o Odisseia, em uma perspectiva polissêmica…</p>
      </div>
      
      <div className="estrofe">
        <p>Odisseia,</p>
        <p>Um grupo constituído de tantas ideias.</p>
        <p>Com sede na UNESPAR,</p>
        <p>Mas sem fronteiras para pensar,</p>
      </div>
      
      <div className="estrofe">
        <p>Surgiu em 2019, inspirado no livro Odisseia, de Homero.</p>
        <p>E tal qual Ulisses, percorrendo caminhos, eu considero</p>
        <p>Que esta equipe percorre por trilhas diversas,</p>
        <p>No encontro com o outro, onde a história se encontra imersa.</p>
      </div>
      
      <div className="estrofe">
        <p>Apresento então os personagens,</p>
        <p>Que fazem do enfrentamento sua ancoragem.</p>
        <p>Trabalhando em perspectivas decolonias,</p>
        <p>No confronto aos pensamentos racistas, patriarcais,</p>
      </div>
      
      <div className="estrofe">
        <p>Xenófobos, misóginos, homofóbicos</p>
        <p>E aos saberes rígidos e puramente teóricos.</p>
        <p>Então, no primeiro ato,</p>
        <p>Temos Fábio Vedovato,</p>
      </div>
      
      <div className="estrofe">
        <p>Que investiga os patrimônios,</p>
        <p>E aqueles que fazem destes os seus sinônimos.</p>
        <p>Em outra ala, temos a Maíra,</p>
        <p>Crianças e memórias, dessa tarefa ela se incumbira.</p>
      </div>
      
      <div className="estrofe">
        <p>Marli, lança o olhar para uma comunidade,</p>
        <p>Aparecida do Oeste, cantinho repleto de singularidade.</p>
        <p>Carolina, busca na literatura,</p>
        <p>A ressignificação da criatura.</p>
      </div>
      
      <div className="estrofe">
        <p>Driele, pesquisadora de gênero,</p>
        <p>Ensina que este assunto está ligado a história em “número e gênero”.</p>
        <p>O menino de Moçambique, Inácio,</p>
        <p>Estuda as memórias dos anciãos, e disso faz o seu “palácio”.</p>
      </div>
      
      <div className="estrofe">
        <p>Gabriel, se lança aos saberes e fazeres,</p>
        <p>De uma comunidade rural, demolindo dos grandes vultos, os poderes.</p>
        <p>Júnior ou Damião,</p>
        <p>Investiga as pinturas rupestres de sua região.</p>
      </div>
      
      <div className="estrofe">
        <p>Beatriz, analisa tribos sociais,</p>
        <p>Que pelo preconceito são vistas como marginais.</p>
        <p>Júlia, viaja na perspectiva de ensino,</p>
        <p>Contribuindo para um saber potente e cristalino.</p>
      </div>
      
      <div className="estrofe">
        <p>Emily analisa as memórias de professores,</p>
        <p>Tornando-os co-autores.</p>
        <p>Kicani, é de Angola,</p>
        <p>Vicente e Mathias também, a eles eu passo a bola,</p>
      </div>
      
      <div className="estrofe">
        <p>Kicani, o jornalista,</p>
        <p>Investiga o kuduro, outra temática importante nessa lista.</p>
        <p>Vicente bebe na fonte de revolucionários,</p>
        <p>De mulheres e homens lendários.</p>
      </div>
      
      <div className="estrofe">
        <p>Mathias, pesquisa sobre as mulheres zungueiras,</p>
        <p>Estas que em seu dia-a-dia são guerreiras.</p>
        <p>Helena, outra coluna,</p>
        <p>Do pós-doutorado é aluna.</p>
      </div>
      
      <div className="estrofe">
        <p>Formado por mestres, mestrandos,</p>
        <p>Doutorandos e pós-doutorandos.</p>
        <p>Esse grupo é um baluarte,</p>
        <p>E tem a história como seu estandarte.</p>
      </div>
      
      <div className="estrofe">
        <p>Refletindo sobre memórias, experiências e narrativas,</p>
        <p>Nossas práticas são sempre colaborativas,</p>
        <p>Propondo uma história a contrapelo</p>
        <p>Resistimos a modernidade e seus atropelos.</p>
      </div>
      
      <span>— Damião Rocha dos Santos Junior</span>
</blockquote>

        </div>
      </div>
    </div>
  );
}

export default GrupoOdisseia;
